
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import dayjs from "dayjs";
import { emptyToLine, splitThousands } from "@/utils/common";
import TableEnableFlag from "@/components/scope/table-enable-flag.vue";
import { Pageniation as Table, TableRowsSelect } from "@/utils/decorator";
import t from "@common/src/i18n/t";
const learning = namespace("learning");
@Component({
  components: { TableEnableFlag }
})
@Table("loadListData")
@TableRowsSelect({
  warningMsg: t("setting.select-spare-parts"),
  updateStatusFuncName: "confirmUpdateStatus",
  tableRefKey: "table"
})
export default class InteractionCategoryList extends Vue {
  @learning.Action queryInvitationClassList;
  @learning.Action createInvitationClass;
  @learning.Action deleteInvitationClass;
  @learning.Action changeInvitationClassSort;

  dayjs = dayjs;
  searchForm = {
    className: ""
  };
  splitThousands = splitThousands;
  emptyToLine = emptyToLine;
  insertForm: any = {
    className: ""
  };
  dialogFormVisible: boolean = false;
  contentClassList: any[] = [];
  total: number = 0;
  get searchList() {
    return [
      {
        label: t("v210831.type-name"),
        type: "input",
        value: "",
        prop: "className"
      }
    ];
  }
  categoryList: any[] = [];
  brandList: any[] = [];
  created() {
    this.init();
  }
  async init() {
    this.loadListData();
  }
  @Table.LoadDataMethod()
  loadListData() {
    return this.queryInvitationClassList({
      invitationClassName: this.searchForm.className
    }).then(data => {
      this.contentClassList = data.data;
      this.total = data.data.length;
      return data;
    });
  }
  @TableRowsSelect.updateMethod
  handleSearch(data) {
    this.mix_pageNum = 1;
    data.forEach(item => {
      if (item.prop) {
        this.searchForm[item.prop] = item.prop === "categoryCode" ? item.value[item.value.length - 1] : item.value;
      }
    });
    console.log(this.searchForm);
    this.loadListData();
  }
  // 排序
  upOne(scope, num) {
    if (this.searchForm.className !== "") {
      this.$message.warning(t("v210831.query-status-does-not-sort"));
      return;
    }
    if (num === 1) {
      return;
    }
    let params = {
      currentClassCode: scope.row.invitationClassCode,
      operateType: "4"
    };
    this.sortArrList(params);
  }
  upTop(scope, num) {
    if (this.searchForm.className !== "") {
      this.$message.warning(t("v210831.query-status-does-not-sort"));
      return;
    }
    if (num === 1) {
      return;
    }
    let params = {
      currentClassCode: scope.row.invitationClassCode,
      operateType: "1"
    };
    this.sortArrList(params);
  }
  downOne(scope, num) {
    if (this.searchForm.className !== "") {
      this.$message.warning(t("v210831.query-status-does-not-sort"));
      return;
    }
    if (num === this.total) {
      return;
    }
    let params = {
      currentClassCode: scope.row.invitationClassCode,
      operateType: "5"
    };
    this.sortArrList(params);
  }
  downBottom(scope, num) {
    if (this.searchForm.className !== "") {
      this.$message.warning(t("v210831.query-status-does-not-sort"));
      return;
    }
    if (num === this.total) {
      return;
    }
    let params = {
      currentClassCode: scope.row.invitationClassCode,
      operateType: "3"
    };
    this.sortArrList(params);
  }
  sortArrList(params) {
    this.changeInvitationClassSort(params).then(() => {
      this.$message.success(t("v210831.sorting-succeeded"));
      this.loadListData();
    });
  }
  // 删除
  handleDelete(row) {
    this.deleteInvitationClass({ invitationClassCode: row.invitationClassCode }).then(() => {
      this.$message.success(t("base.delete-success"));
      this.loadListData();
    });
  }
  // 新增
  handleInsert() {
    if (this.insertForm.className == "") {
      return;
    }
    let repeat = false;
    this.contentClassList.forEach(v => {
      if (v.invitationClassName === this.insertForm.className) {
        repeat = true;
      }
    });
    if (repeat) {
      this.$message.warning(t("v210831.classification-name-already"));
    } else {
      this.createInvitationClass({ invitationClassName: this.insertForm.className }).then(() => {
        this.loadListData();
        this.dialogFormVisible = false;
        this.$message.success(t("finance.new-success"));
        this.insertForm.className = "";
      });
    }
  }
}
